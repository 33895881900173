<template>
  <section>
    <data-table-component
      v-models:select="perPage"
      v-models:pagina="page"
      :headers="headers"
      :items="tableItems"
      @paginar="paginate"
      :total_registros="totalRows"
      item-key="row_key"
    >
      <template v-slot:[`item.fecha_contratacion`]="{ item }">
        {{ moment(item.fecha_contratacion).format("DD/MM/YYYY") }}
      </template>
      <template v-slot:[`item.acciones`]="{ item }">
        <v-btn icon small @click="(contrato = item), (modal_edicion = true)">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </template>
    </data-table-component>
    <v-dialog v-model="modal_edicion" persistent>
      <v-card>
        <v-card-title class="d-flex align-center">
          <v-icon class="mr-2">mdi mdi-text-box-search</v-icon>
          <span class="font-weight-bold mr-2"
            >Gestionar administradores de contrato</span
          >
        </v-card-title>
        <v-card-text>
          <v-tabs grow v-model="tab">
            <v-tab> Administradores </v-tab>
            <v-tab> Agregar </v-tab>
            <v-tab-item>
              <br />
              <v-data-table
                :items="tableItemsAdministrador"
                :headers="headers_administradores"
                item-key="row_key"
              >
                <template v-slot:[`item.acciones`]="{ item }">
                  <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        icon
                        small
                        @click="
                          (deleteModal = true),
                            (id_empleado_solicitud_compra =
                              item.id_empleado_solicitud_compra)
                        "
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </template>
                    <span>Eliminar</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-tab-item>
            <v-tab-item>
              <br />
              <v-row>
                <v-col cols="12" sm="6">
                  <v-autocomplete
                    v-model="form.id_empleado"
                    :items="empleados_disponibles"
                    item-value="id"
                    :item-text="(item) => `${item?.nombre} - (${item?.correo})`"
                    label="Empleado"
                    outlined
                    clearable
                  />
                </v-col>
                <v-col cols="12" sm="6">
                  <v-autocomplete
                    v-model="form.id_solicitud"
                    :items="solicitudes_disponibles"
                    item-value="id"
                    item-text="codigo"
                    label="Solicitud específica"
                    outlined
                    clearable
                  >
                    <template v-slot:selection="{ item }">
                      {{
                        `${item?.codigo ?? ""} 
                      (${item?.nombre_unidad ?? ""}) 
                      `
                      }}
                    </template>
                    <template v-slot:item="{ item }">
                      {{
                        `${item?.codigo ?? ""} 
                      (${item?.nombre_unidad ?? ""}) 
                      `
                      }}
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
              <div class="d-flex justify-end mt-4">
                <v-btn
                  class="flex-grow-1 flex-shrink-1"
                  @click.stop="cerrarModal"
                  color="secondary"
                  >Cerrar</v-btn
                >
                <v-btn
                  class="flex-grow-1 flex-shrink-1 ml-4"
                  @click="postAdministradoresContrato"
                  color="primary"
                  >Guardar</v-btn
                >
              </div>
            </v-tab-item>
          </v-tabs>
          <div class="d-flex justify-end mt-4" v-if="tab === 0">
            <v-btn
              class="flex-grow-1 flex-shrink-1"
              @click.stop="cerrarModal"
              color="secondary"
              >Cerrar</v-btn
            >
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <dialogoConfirmacion
      :show="deleteModal"
      :title="`¿Desea remover a esta persona del seguimiento del contrato ${contrato?.numero_contrato}?`"
      message="De confirmarse la siguiente acción, los cambios no serán reversibles."
      btnConfirmar="Aceptar"
      @close="(deleteModal = false), (id_empleado_solicitud_compra = null)"
      @confirm="deleteSeguidorContractual()"
    />
  </section>
</template>
<script>
import DataTableComponent from "@/components/DataTableComponent.vue";
import dialogoConfirmacion from "@/components/ConfirmationDialogComponent.vue";

export default {
  name: "ReasignacionesContratos",
  components: {
    DataTableComponent,
    dialogoConfirmacion,
  },
  data() {
    return {
      page: 1,
      perPage: 10,
      headers: [
        { text: "Numero de contrato", value: "numero_contrato" },
        { text: "Institucion", value: "institucion" },
        { text: "Nombre del contrato", value: "nombre_contrato" },
        { text: "Fecha de contratacion", value: "fecha_contratacion" },
        // { text: "Código solicitud específica", value: "solicitud_especifica" },
        { text: "Acciones", value: "acciones" },
      ],
      headers_administradores: [
        { text: "Nombres", value: "nombres" },
        { text: "Apellidos", value: "apellidos" },
        { text: "Institucion", value: "institucion" },
        { text: "Acciones", value: "acciones" },
      ],
      items: [],
      totalRows: 0,
      modal_edicion: false,
      contrato: {},
      administradores: [],
      deleteModal: false,
      id_empleado_solicitud_compra: null,
      empleados_disponibles: [],
      solicitudes_disponibles: [],
      tab: null,
      form: {
        id_solicitud: null,
        id_empleado: null,
      },
    };
  },
  computed: {
    tableItems() {
      return this.items.map((item) => ({
        ...item,
        row_key: `${item.id}-${item.solicitud_especifica}`,
      }));
    },
    tableItemsAdministrador() {
      return this.administradores.map((item, index) => ({
        ...item,
        row_key: `${item.id}-${index}`,
      }));
    },
  },
  methods: {
    paginate(page, perPage) {
      this.page = page;
      this.perPage = perPage;
      this.getReasignacionesContratos();
    },
    async getContratosInstitucion() {
      const { data, headers } =
        await this.services.reasignacionContractual.listarContratosInstitucion({
          page: this.page,
          perPage: this.perPage,
        });
      this.items = data;
      this.totalRows = parseInt(headers.total_rows);
    },
    async getAdministradoresContrato() {
      const { data } =
        await this.services.reasignacionContractual.obtenerListadoAdministradores(
          this.contrato?.id
        );
      this.administradores = data;
    },
    async paginate(params) {
      const { cantidad_por_pagina, pagina } = params;
      this.perPage = cantidad_por_pagina;
      this.page = pagina;
      await this.getContratosInstitucion();
    },
    async deleteSeguidorContractual() {
      if (this.administradores.length <= 1) {
        this.deleteModal = false;
        return this.pushAppMessage({
          show: true,
          message: "Es requerido almenos un administrador de contrato.",
          type: "error",
          timeout: 5000,
        });
      }
      await this.services.reasignacionContractual.eliminarSeguidorContractual(
        this.id_empleado_solicitud_compra
      );
      this.deleteModal = false;
      this.id_empleado_solicitud_compra = null;
      this.getAdministradoresContrato();
    },
    async getEmpleadosDisponibles() {
      const { data } =
        await this.services.reasignacionContractual.listarEmpleadosDisponibles();
      this.empleados_disponibles = data;
    },
    async getSolicitudesDisponibles() {
      const { data } =
        await this.services.reasignacionContractual.listarSolicitudesDisponibles(
          this.contrato?.id
        );
      this.solicitudes_disponibles = data;
    },
    async postAdministradoresContrato() {
      if (!this.form.id_solicitud || !this.form.id_empleado) {
        this.pushAppMessage({
          show: true,
          message: "Debe seleccionar un empleado y una solicitud específica",
          type: "error",
        });
        return;
      }

      await this.services.reasignacionContractual.agregarAdministradorContrato(
        this.form
      );
      this.form = {
        id_solicitud: null,
        id_empleado: null,
      };
      this.getAdministradoresContrato();
    },
    cerrarModal() {
      this.modal_edicion = false;
      this.form = {};
      this.tab = 0;
    },
  },
  watch: {
    contrato: {
      handler() {
        if (this.contrato?.id) {
          this.getAdministradoresContrato();
          this.getEmpleadosDisponibles();
          this.getSolicitudesDisponibles();
        }
      },
      deep: true,
    },
  },
  async created() {
    await this.getContratosInstitucion();
  },
};
</script>
